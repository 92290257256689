import React, { Fragment } from 'react';
import Header from '../../components/header/Header';
import Hero3 from '../../components/hero3/hero3';
import PartnerSection from '../../components/business-consulting-components/PartnerSection/PartnerSection';
import EmpoweringSuccess from '../../components/business-consulting-components/EmpoweringSuccess/EmpoweringSuccess';
import ConsultingService from '../../components/business-consulting-components/ConsultingService/ConsultingService';
import BusinessTestimonial from '../../components/business-consulting-components/BusinessTestimonial/BusinessTestimonial';
import BusinessBlogSection from '../../components/business-consulting-components/BusinessBlogSection/BusinessBlogSection';
import BusinessContact from '../../components/business-consulting-components/BusinessContact/BusinessContact';
import BusinessFooter from '../../components/business-consulting-components/BusinessFooter/BusinessFooter';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import FaqSection from '../../components/software-company-components/FaqSection/FaqSection';


const ServiceXNovaQPage = (props) => {
    const faqData = [
        {
          question: 'What is Direct Hire Staffing?',
          answer: 'Direct hire staffing is a recruitment service where XNovaQ acts as your partner to identify, screen, and place suitable candidates for permanent positions within your organization. We handle the entire hiring process, from sourcing to onboarding, saving you time and resources.'
        },
        {
          question: 'How does XNovaQ\'s Direct Hire Staffing process work?',
          answer: 'Our process involves: Understanding your needs, sourcing and screening candidates, facilitating interviews, conducting reference checks and background verifications, and assisting with offer extensions and onboarding.'
        },
        {
          question: 'What industries does XNovaQ specialize in for Direct Hire Staffing?',
          answer: 'XNovaQ offers direct hire staffing services across a wide range of industries, including Technology, Healthcare, Finance, Engineering, Manufacturing, Retail, and more.'
        },
        {
          question: 'How long does the Direct Hire Staffing process typically take?',
          answer: 'The timeline for direct hire staffing varies depending on the role, industry, and availability of suitable candidates, but XNovaQ strives to expedite the process without compromising on quality.'
        },
        {
          question: 'What is the cost of Direct Hire Staffing with XNovaQ?',
          answer: 'Our fees are competitive and based on a contingency model. We charge a fee upon successful placement, making it a cost-effective solution for your hiring needs.'
        },
        {
          question: 'Can I interview candidates myself?',
          answer: 'Yes, you have the opportunity to interview candidates personally to assess their fit within your organization. XNovaQ can provide guidance and support throughout the interview process.'
        },
        {
          question: 'How do you ensure candidate quality?',
          answer: 'XNovaQ employs rigorous screening processes, including background checks, reference verification, and in-depth interviews to ensure the quality of candidates we present.'
        },
        {
          question: 'What if I\'m not satisfied with the candidates presented?',
          answer: 'We maintain open communication and work closely with you to understand your preferences. If you\'re not satisfied with the initial candidates, we\'ll continue our search to find the perfect match.'
        },
        {
          question: 'Can you help with onboarding new hires?',
          answer: 'Yes, XNovaQ can provide support with onboarding to ensure a smooth transition for new employees. We can assist with paperwork, introductions, and any necessary training.'
        },
        {
          question: 'How does XNovaQ differentiate itself from other staffing agencies?',
          answer: 'XNovaQ stands out due to: deep industry expertise, personalized service, strong candidate network, and efficient processes that save you time and resources.'
        }
      ];

    return (
        <Fragment>
            <div className='index_business_consulting'>
                <main className="page_content s2">
                    <Header />
                    <Hero3 />
                    {/* <PartnerSection /> */}
                    <EmpoweringSuccess />
                    <ConsultingService />
                    <BusinessTestimonial />
                    {/* <BusinessBlogSection /> */}
                    {/* <BusinessContact /> */}
                    {/* <BusinessFooter /> */}
                    <FaqSection 
                        title="F.A.Q." 
                        subtitle="Need a Support?" 
                        faqs={faqData} 
                    />
                    <Footer />
                    <Scrollbar />
                </main>
            </div>
        </Fragment>
    )
}

export default ServiceXNovaQPage;